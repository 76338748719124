body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiButton-containedPrimary {
  color: white !important
}

.MuiButton-containedPrimary:hover {
  color: black !important
}

.MuiBadge-colorPrimary {
  background-color: #2ec62e !important;
}

.MuiMenu-paper {
  margin-top: 36px !important
}

.MuiButton-root {
  text-transform: none !important;
  font-weight: bold !important
}

.slick-slide {
	padding: 0 8px;
  box-sizing: border-box;
  margin: 4px 0;
}

.slick-dots li {
  margin: 0 !important;
  width: 12px !important;
  height: 12px !important;
}

.slick-dots li.slick-active button:before {
  color: white !important;
  font-size: 8px !important;
  opacity: 1 !important;
}

.slick-dots li button:before {
  color: white !important;
  font-size: 8px !important;
  opacity: 0.75 !important;
}

.MuiExpansionPanelSummary-root.Mui-focused {
  background-color: transparent !important;
}

.MuiTableCell-paddingNone {
  padding: 4px 8px !important;
}

.MuiBottomNavigationAction-label.Mui-selected {
  font-size: 0.75rem !important;
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 8px 0;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-align: center;
  font-size: 10px;
  font-weight: bold;
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 48px;
  transform: rotate(45deg);
}

@media only screen and (max-width: 600px) {
  .ribbon {
    display: none;
  }

  .slick-slide {
    padding: 4px;
  }
  .MuiTabs-root{
    margin-left: -16px;
  }
  .MuiDialog-paperFullWidth {
    width:100% !important
  }
  .MuiDialog-paper{
    margin: 0 !important
  }
  .MuiDialog-paperScrollPaper {
    max-height: 100% !important;
  }
  .MuiDialogContent-root {
    padding: 8px !important;
    overflow-x: hidden !important;
  }
}

/* .MuiBackdrop-root {
  background-color: #00000010 !important;
} */